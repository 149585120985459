import request from '@core/utils/RequestApi'
import qs from 'qs'

export function Create(data) {
  return request({
    url: '/Customer/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function CreateWithCheck(data) {
  return request({
    url: '/Customer/CreateWithCheck',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Customer/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/Customer/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}

export function Get() {
  return request({
    url: '/Customer/Get',
    method: 'get',
  })
}
export function GetByPhoneNumber(data) {
  return request({
    url: '/Customer/GetByPhoneNumber',
    method: 'get',
    params: data,
  })
}
export function GetForManager(data) {
  return request({
    url: '/Customer/GetForManager',
    method: 'get',
    params: data,
  })
}
