<template>
  <v-dialog :value="isDialogEdit" max-width="750px" @input="val => $emit('update:is-dialog-edit', val)">
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Customer : {{ Customer.Id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="app-item-preview px-4 ma-0">
              <v-col cols="8" md="4">
                <v-text-field
                  v-model="CustomerData.Name"
                  outlined
                  dense
                  :rules="[validators.required]"
                  label="Name"
                  placeholder="Name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="8" md="4">
                <v-text-field
                  v-model="CustomerData.PhoneNumber"
                  outlined
                  dense
                  :rules="[validators.required]"
                  label="Phone Number"
                  placeholder="Phone Number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="8" md="4">
                <v-text-field
                  v-model="CustomerData.Description"
                  outlined
                  dense
                  label="Description"
                  placeholder="Description"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="8" md="4">
                <v-select
                  v-model="CustomerData.Status"
                  :rules="[validators.required]"
                  label="Status"
                  :items="$store.state.status['customers']"
                  item-text="title"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  class="mb-6"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { Edit } from '@core/api/Customers'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    Customer: { type: Object, required: true },
  },
  watch: {
    Customer(New) {
      this.CustomerData = New
    },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      AreaId: undefined,
      Name: '',
      Description: '',
      PhoneNumber: '',
      Status: 'active',
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const CustomerData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetCustomerData = () => {
      CustomerData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-dialog-edit', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        Edit(CustomerData.value).then(() => {
          emit('refetch-data')
          emit('update:is-dialog-edit', false)
        })

        resetCustomerData()
      } else {
        validate()
      }
    }
    const close = () => {
      resetCustomerData()
    }

    return {
      resetCustomerData,
      form,
      onSubmit,
      CustomerData,
      valid,
      validate,
      mdiPlus,
      close,
      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
