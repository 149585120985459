import { Get } from '@core/api/Customers'
import { ref, watch } from '@vue/composition-api'

export default function useCustomerList() {
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name' },
    { text: 'PhoneNumber', value: 'PhoneNumber', sortable: false },
    { text: 'Description', value: 'Description', sortable: false },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]

  const CustomerListTable = ref([])
  const searchQuery = ref('')
  const statusFilter = ref(null)
  const totalCustomerListTable = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const CustomerTotalLocal = ref([])
  const loading = ref(false)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchCustomers = () => {
    Get()
      .then(response => {
        // handle success
        CustomerListTable.value = response
        totalCustomerListTable.value = response.lenght
        CustomerTotalLocal.value = response.lenght
        loading.value = false
      })
      .catch(error => {
        loading.value = false

        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, options], () => {
    loading.value = true

    fetchCustomers()
  })
  return {
    CustomerListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    totalCustomerListTable,
    options,
    CustomerTotalLocal,
    fetchCustomers,
  }
}
