<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-btn right color="primary" class="mb-4 me-3" @click.stop="isAddActive = !isAddActive">
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Customer</span>
          </v-btn>
        </v-col>
      </v-row>
      <customer-add-new @refetch-data="fetchCustomers" v-model="isAddActive"></customer-add-new>
      <!-- <customer-delete @refetch-data="fetchCustomers" :Id="customerTemp.Id" v-model="isDialogDelete" /> -->
      <customer-edit @refetch-data="fetchCustomers" :Customer="customerTemp" v-model="isDialogEdit" />
      <!-- Main Card -->
      <app-card-actions @refresh="fetchCustomers">
        <template slot="title"> Customer List </template>

        <v-card-text>
          <v-data-table
            :headers="tableColumns"
            :items="CustomerListTable"
            :options.sync="options"
            :server-items-length="totalCustomerListTable"
            :loading="loading"
            :sort-desc="[true]"
          >
            <!-- Id -->
            <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
            <!-- status -->
            <template #[`item.Status`]="{ item }">
              <v-chip
                small
                :color="
                  $store.state.status['customers'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                "
                :class="`${
                  $store.state.status['customers'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                }--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.Status }}
              </v-chip>
            </template>
            <!-- Actions -->
            <template #[`item.Actions`]="{ item }">
              <div class="demo-space-x">
                <!-- <v-btn
                  icon
                  color="error"
                  @click.stop=";(isDialogDelete = !isDialogDelete), (customerTemp = { ...item })"
                >
                  <v-icon small>
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn> -->
                <v-btn icon color="primary" @click.stop=";(isDialogEdit = !isDialogEdit), (customerTemp = { ...item })">
                  <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </app-card-actions>
    </v-col>
  </v-row>
</template>
<script>
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
import { ref } from '@vue/composition-api'
import CustomerAddNew from './CustomerAddNew.vue'
// import CustomerDelete from './CustomerDelete.vue'
import CustomerEdit from './CustomerEdit.vue'
import useCustomerList from './useCustomerList'

export default {
  components: {
    CustomerAddNew,
    // CustomerDelete,
    CustomerEdit,
    AppCardActions,
  },
  setup() {
    const isAddActive = ref(false)
    // const isDialogDelete = ref(false)
    const isDialogEdit = ref(false)
    const {
      CustomerListTable,
      tableColumns,
      searchQuery,
      totalCustomerListTable,
      options,
      CustomerTotalLocal,
      fetchCustomers,
      loading,
    } = useCustomerList()
    let customerTemp = ref({ Id: 0 })
    return {
      customerTemp,
      isAddActive,
      // isDialogDelete,
      isDialogEdit,
      CustomerListTable,
      tableColumns,
      searchQuery,
      totalCustomerListTable,
      options,
      CustomerTotalLocal,
      fetchCustomers,
      loading,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
